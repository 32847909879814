<template>
          <!-- Contact Start -->
        <section class="contact-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <h4 class="text-center">
                          <span class="fw-bold badge text-bg-primary">v 1.0.0</span>
                        </h4>
                    </div>
                  <div class="col-md-9">
                    <h4 class="mb-10">Production Ready Features</h4>
                    <p class="mb-10">Explore the latest updates and enhancements now available in our application.</p>

                    <div class="row">
                      <div class="col-md-6">
                        <p>New Features:</p>
                        <ul>
                          <li><strong>-</strong> Google Login Integration</li>
                          <li><strong>-</strong> In-App Notifications</li>
                          <li><strong>-</strong> Pull-to-Refresh for Lists and Items Pages</li>
                        </ul>
                      </div>

                      <div class="col-md-6">
                        <p>Additional Improvements:</p>
                        <ul>
                          <li><strong>-</strong> Enhanced User Experience</li>
                          <li><strong>-</strong> Text Wrapping Issues Resolved</li>
                          <li><strong>-</strong> Added "pcs" as a Unit Option</li>
                          <li><strong>-</strong> Seamless Navigation Without Loading Screens</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </section>
        <!-- Contact End -->
</template>

<script>
import emailjs from "emailjs-com";

export default {

  data: function() {
    return {
      submitButton: 'Send Message',
      form: {
        email: '',
        subject: '',
        message: '',
        terms: false
      }
    }
  },

  methods: {
    sendMail() {

      if (!this.form.terms) {
        return alert('Check terms and conditions');
      }

      this.submitButton = "Sending...";

      const success = document.getElementById("success");
      const failed = document.getElementById("failed");
      const button = document.getElementById("submit");

      button.disabled = true;

      emailjs.sendForm('service_a43t7nh', 'template_biy7y1d', '#contact_form', 'UNDkxFzh6pKsX3wnD')
          .then(() => {
            success.classList.remove('d-none');
            failed.classList.add('d-none');

            this.submitButton = "Send Message";
            button.disabled = false;
            this.form = {
              email: '',
              subject: '',
              message: ''
            }

          }, (error) => {
            console.log(error.text);
            failed.classList.add('show');
            this.submitButton = "Send Message";
            button.disabled = false;
            this.form = {
              email: '',
              subject: '',
              message: ''
            }
          });
    }
  }

}
</script>

<style>

</style>