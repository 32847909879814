<script>
import Privacy1 from "@/components/Privacy/index";

export default {
  name: "Privacy",
  components: {Privacy1  },

}
</script>

<template>
  <privacy1/>
</template>

<style scoped>

</style>