<template>
          <!-- Contact Start -->
        <section class="contact-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="contact--info-area">
                            <h3>Get in touch</h3>
                            <p>
                                Looking for help? Fill the form and start a new adventure.
                            </p>
                            <div class="single-info">
                                <h5>Headquaters</h5>
                                <p>
                                    <i class="fal fa-home"></i>
                                   Bucharest, Romania
                                </p>
                            </div>
                            <div class="single-info">
                                <h5>Support</h5>
                                <p>
                                    <i class="fal fa-envelope"></i>
                                    grocer-mate@vladutteodor.ro<br>
                                </p>
                            </div>
                            <div class="ab-social">
                                <h5>Follow Us</h5>
                                <a class="fac" href="#"><i class="fab fa-facebook-f"></i></a>
                                <a class="twi" href="#"><i class="fab fa-twitter"></i></a>
                                <a class="you" href="#"><i class="fab fa-youtube"></i></a>
                                <a class="lin" href="#"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="contact-form">
                            <h4>Let’s Connect</h4>
                            <p>We value your feedback and are here to help with any questions, concerns, or suggestions you may have about Grocer Mate. Please feel free to reach out to us using the contact information below.</p>

                          <div id='success' class="d-none"> <h4 class="text-success" >Mesajul tau a fost trimis...</h4></div>
                          <div id='failed' class='d-none'><h4 class="text-danger" >A aparut o eroare...</h4></div>

                          <form action="#" class="row" id="contact_form">
                                <div class="col-md-6">
                                    <input type="email" name="email" v-model="form.email" placeholder="Email Address">
                                </div>
                                <div class="col-md-12">
                                    <input type="text" name="suject" v-model="form.subject" placeholder="Subject">
                                </div>
                                <div class="col-md-12">
                                    <textarea name="message" v-model="form.message" placeholder="How can we help?"></textarea>
                                </div>
                                <div class="col-md-6">
                                    <div class="condition-check">
                                        <input id="terms-conditions" type="checkbox" v-model="form.terms">
                                        <label for="terms-conditions">
                                            I agree to the <a href="/terms">Terms & Conditions</a>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-12 mt-3 text-right">
                                    <input @click="sendMail()" class="btn btn-primary" id="submit" name="submit" v-model="submitButton">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact End -->
</template>

<script>
import emailjs from "emailjs-com";

export default {

  data: function() {
    return {
      submitButton: 'Send Message',
      form: {
        email: '',
        subject: '',
        message: '',
        terms: false
      }
    }
  },

  methods: {
    sendMail() {

      if (!this.form.terms) {
        return alert('Check terms and conditions');
      }

      this.submitButton = "Sending...";

      const success = document.getElementById("success");
      const failed = document.getElementById("failed");
      const button = document.getElementById("submit");

      button.disabled = true;

      emailjs.sendForm('service_a43t7nh', 'template_biy7y1d', '#contact_form', 'UNDkxFzh6pKsX3wnD')
          .then(() => {
            success.classList.remove('d-none');
            failed.classList.add('d-none');

            this.submitButton = "Send Message";
            button.disabled = false;
            this.form = {
              email: '',
              subject: '',
              message: ''
            }

          }, (error) => {
            console.log(error.text);
            failed.classList.add('show');
            this.submitButton = "Send Message";
            button.disabled = false;
            this.form = {
              email: '',
              subject: '',
              message: ''
            }
          });
    }
  }

}
</script>

<style>

</style>