<script>
import Terms1 from "@/components/Terms/index";

export default {
  name: "Terms",
  components: {Terms1  },

}
</script>

<template>
  <Terms1/>
</template>