<template>
    <Changelog/>
</template>

<script>
import Changelog from '../components/Changelog/index'
export default {
	components: {Changelog},

}
</script>

<style>

</style>