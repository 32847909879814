<script>
export default {
  name: "test",
  mounted() {
    // alert(2)
  },
  methods: {
    redirect() {
      window.open("https://grocermate.ro/test-app");
    },
    redirect2() {
      window.open("https://grocermate.ro/test-app", "_blank");
    }
  }
}
</script>

<template>
  <div >

    <h2 @click="redirect">Redirect</h2>
    <h2 @click="redirect2">Redirect2</h2>

    Hello World! <a href="https://grocermate.ro/test-app">Test 1</a> <br>
    <a href="https://grocermate.ro/test-app" target="_self">Test 2</a>
  </div>
</template>

<style scoped>

</style>