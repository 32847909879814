<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget">
            <div class="logo">
              <a href="#"><img src="@/assets/images/grocer-mate-bg.png" alt="" /></a>
            </div>
            <p>
              Grocer Mate is an innovative app designed for creating and sharing shopping lists with friends and family, making your grocery shopping more organized and efficient.
            </p>
<!--            <a href="#">Read More <i class="fal fa-arrow-right"></i></a>-->
<!--            <div class="social mt-30">-->
<!--              <ul>-->
<!--                <li>-->
<!--                  <a href="#"><i class="fab fa-facebook-f"></i></a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="#"><i class="fab fa-twitter"></i></a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="#"><i class="fab fa-pinterest-p"></i></a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="#"><i class="fab fa-linkedin-in"></i></a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Developer</h4>
            <ul>
              <li><a href="https://vladutteodor.ro">About Me</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Support</h4>
            <ul>
              <li><router-link to="/contact">Contact</router-link></li>
              <li><router-link to="/privacy">Privacy Policy</router-link></li>
              <li><router-link to="/terms">Terms and conditions</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">Get In Touch</h4>
            <ul>
              <li>
                <a href="#"
                  ><i class="fal fa-envelope"></i> grocer-mate@vladutteodor.ro</a
                >
              </li>
<!--              <li>-->
<!--                <a href="#"><i class="fal fa-phone"></i> +(40) 773 379 253</a>-->
<!--              </li>-->
              <li>
                <a href="#"
                  ><i class="fal fa-map-marker-alt"></i> Bucharest, Romania</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="
              footer-copyright
              d-flex
              align-items-center
              justify-content-between
              pt-35
            "
          >
            <div class="apps-download-btn">
              <ul>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=grocer.mate.yzy"
                  ><i class="fab fa-google-play"></i> Download for Android</a
                  >
                </li>
                <li>
                  <a class="item-2" href="#"><i class="fab fa-apple"></i> Download for iOS</a>
                </li>

              </ul>
            </div>
            <div class="copyright-text">
              <p>Copyright © 2024 Grocer Mate. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {};
</script>

<style>
</style>