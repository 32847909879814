<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>
        <div class="layer layer-three"><span class="overlay"></span></div>
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />
    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-home-seven @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== PART ENDS ======-->

    <!--====== APPIE PAGE TITLE PART START ======-->

    <!-- reference by news -->
    <div class="appie-page-title-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="appie-page-title-item">
              <h3 class="title">Privacy and Policy</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--====== APPIE PAGE TITLE PART ENDS ======-->

    <!-- Contact Start -->

    <div class="container">
      <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
          <p>Last updated: 11 Jul 2023</p>

          <p>This Privacy Policy describes how Vladut Teodor ("I", "me", "my") collects, uses, and shares information about you when you use the Grocer Mate ("App"). By using the App, you agree to the collection and use of information in accordance with this policy.</p>

          <h2>1. Information Collection</h2>
          <p>I collect several types of information to provide and improve the App for you:</p>
          <ul>
            <li><strong>Personal Information:</strong> When you register for an account, I may collect personal information such as your name, email address, and password.</li>
            <li><strong>Usage Data:</strong> I collect information on how the App is accessed and used. This may include your device's Internet Protocol (IP) address, browser type, browser version, the pages of the App that you visit, the time and date of your visit, and other diagnostic data.</li>
            <li><strong>Cookies and Tracking Technologies:</strong> I use cookies and similar tracking technologies to track the activity on the App and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</li>
          </ul>

          <h2>2. Use of Information</h2>
          <p>I use the collected information for various purposes:</p>
          <ul>
            <li>To provide and maintain the App</li>
            <li>To notify you about changes to the App</li>
            <li>To allow you to participate in interactive features of the App when you choose to do so</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information so that I can improve the App</li>
            <li>To monitor the usage of the App</li>
            <li>To detect, prevent, and address technical issues</li>
          </ul>

          <h2>3. Information Sharing</h2>
          <p>I do not share your personal information with third parties except as described in this Privacy Policy:</p>
          <ul>
            <li><strong>With your consent:</strong> I may share your personal information with third parties when you have given me your consent to do so.</li>
            <li><strong>Service Providers:</strong> I may employ third-party companies and individuals to facilitate the App, provide the App on my behalf, perform App-related services, or assist me in analyzing how the App is used.</li>
            <li><strong>Legal Requirements:</strong> I may disclose your personal information if required to do so by law or in response to valid requests by public authorities.</li>
          </ul>

          <h2>4. Data Security</h2>
          <p>The security of your data is important to me. While I strive to use commercially acceptable means to protect your personal information, I cannot guarantee its absolute security.</p>

          <h2>5. Data Retention</h2>
          <p>I will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. I will retain and use your personal information to the extent necessary to comply with my legal obligations, resolve disputes, and enforce my legal agreements and policies.</p>

          <h2>6. Your Data Protection Rights</h2>
          <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. I aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your personal information. If you wish to be informed what personal information I hold about you and if you want it to be removed from my systems, please contact me at <a href="mailto:grocer-mate@vladutteodor.ro">grocer-mate@vladutteodor.ro</a>.</p>

          <h2>7. Changes to This Privacy Policy</h2>
          <p>I may update this Privacy Policy from time to time. I will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

          <h2>8. Contact Me</h2>
          <p>If you have any questions about this Privacy Policy, please contact me at:</p>
          <p>Email: <a href="mailto:grocer-mate@vladutteodor.ro">grocer-mate@vladutteodor.ro</a></p>
        </div>
      </div>
    </div>
    <!-- Contact End -->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import HeaderHomeSeven from "@/components/HomeSeven/HeaderHomeSeven.vue";
export default {
  components: {
    HeaderHomeSeven,
    SidebarHomeOne,
    FooterHomeOne,
  },
  data() {
    return {
      sidebar: false,
      navs: [
        {
          name: "home",
          path: '/'
        },
        {
          name: "Downloads",
          childrens: [
            {
              name: "Android",
              href: "https://play.google.ro",
            },
            {
              name: "IOS",
              href: "https://play.apple.com",
            },
          ]
        },
        {
          name: "Terms",
          path: "/terms"
        },
        {
          name: "Privacy",
          path: "/privacy"
        },
        {
          name: "Changelog",
          path: "/changelog"
        },
        {
          name: "contact",
          path: "/contact",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>