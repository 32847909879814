<template>
      <!--====== APPIE HERO PART START ======-->
    
    <section class="appie-hero-area appie-hero-6-area appie-hero-7-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="appie-hero-content appie-hero-content-6 appie-hero-content-7">
                        <h1 class="appie-title">Grocer Mate</h1>
                        <p>You can easily share your online shopping list with your friends, allowing them to see what you need and even add items themselves. This makes planning and coordinating purchases much more convenient.</p>
                        <ul>
                            <li><a href="https://play.google.com/store/apps/details?id=grocer.mate.yzy"><i class="fab fa-google-play"></i> <span>Available on the <span>Google Play</span></span></a></li>
                            <li><a class="item-2" href="#"><i class="fab fa-apple"></i> <span>Working on version for <span>App Store</span></span></a></li>
                        </ul>
                        <div class="info">
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="appie-hero-thumb-6">
                        <div class="thumb wow animated fadeInUp text-center" data-wow-duration="1000ms" data-wow-delay="600ms">
                            <img src="@/assets/images/devices/empty_list_view.png" alt="">
                            <div class="back-image">
                                <img src="@/assets/images/hero-shape-2.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE HERO PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>