<template>
    <ul>
        <li v-for="(item, index) in menuItems" :key="index">
            <router-link style="text-transform: capitalize;" v-if="item.path" :to="item.path">{{item.name}}</router-link>

            <a v-if="typeof item.path === 'undefined' && typeof item.href === 'undefined'" :href="'#'" style="text-transform: capitalize;">{{item.name}} <i v-if="nasted" class="fal fa-angle-down"></i></a>
            <a v-if="typeof item.href !== 'undefined'" :href="item.href ? item.href : '#'" style="text-transform: capitalize;">{{item.name}}</a>

            <ul v-if="item.childrens && nasted" class="sub-menu">
                <li v-for="(subItem, index) in item.childrens" :key="index">
                  <a v-if="typeof subItem.href !== 'undefined'" :href="subItem.href ? subItem.href : '#'" style="text-transform: capitalize;">{{subItem.name}}</a>
                  <router-link v-else style="text-transform: capitalize;" :to="subItem.path?subItem.path:'#'">{{subItem.name}}</router-link>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
export default {
    props:{
        menuItems: {
            type:Array,
            required:true,
        },
         nasted:{
            type:Boolean,
            default:true
        }
    }

}
</script>

<style>

</style>