<template>
  <div>
          <!--====== APPIE PAGE TITLE PART START ======-->
    
    <div class="appie-page-title-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-page-title-item">
                        <h3 class="title">Terms and conditions</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!--====== APPIE PAGE TITLE PART ENDS ======-->
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>