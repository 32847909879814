<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>
        <div class="layer layer-three"><span class="overlay"></span></div>
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />
    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-home-seven @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== PART ENDS ======-->

    <!--====== APPIE PAGE TITLE PART START ======-->

    <!-- reference by news -->
    <title-area />

    <!--====== APPIE PAGE TITLE PART ENDS ======-->

    <!-- Terms Start -->
    <div class="container">

      <div class="row">
        <div class="col-md-1">
        </div>
        <div class="col-md-10">

          <h2>1. Introduction</h2>
          <p>Welcome to Grocer Mate ("App"). These terms and conditions ("Terms") govern your use of the App, developed and operated by Vladut Teodor ("Developer", "I", "me", "my"). By downloading, installing, or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the App.</p>
          <br>
          <h2>2. Use of the App</h2>
          <h3>2.1 Eligibility</h3>
          <p>You must be at least 13 years old to use the App. By using the App, you represent and warrant that you meet this eligibility requirement.</p>

          <h3>2.2 Account Registration</h3>
          <p>To use certain features of the App, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and for all activities that occur under your account.</p>

          <h3>2.3 License</h3>
          <p>Subject to your compliance with these Terms, I grant you a limited, non-exclusive, non-transferable, revocable license to use the App for your personal, non-commercial use.</p>
          <br>
          <h2>3. Creating and Sharing Shopping Lists</h2>
          <h3>3.1 Creating Lists</h3>
          <p>The App allows you to create shopping lists. You are responsible for the content of your lists and ensuring that they do not contain prohibited or inappropriate material.</p>

          <h3>3.2 Sharing Lists</h3>
          <p>You may share your shopping lists with others through the App. When sharing a list, you grant the recipient a license to view, edit, and use the list as intended.</p>
          <br>
          <h2>4. User Conduct</h2>
          <p>You agree not to:</p>
          <ul>
            <li>Use the App for any illegal or unauthorized purpose.</li>
            <li>Share or post any content that is illegal, harmful, abusive, defamatory, or otherwise objectionable.</li>
            <li>Interfere with or disrupt the operation of the App.</li>
          </ul>
          <br>
          <h2>5. Privacy</h2>
          <p>Your use of the App is also governed by my Privacy Policy, which can be found at <router-link to="/privacy">Click</router-link>. By using the App, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.</p>
          <br>

          <h2>6. Intellectual Property</h2>
          <p>All intellectual property rights in the App, including but not limited to software, text, graphics, logos, and trademarks, are owned by or licensed to me. You may not reproduce, distribute, or create derivative works based on the App without my prior written consent.</p>
          <br>

          <h2>7. Termination</h2>
          <p>I may terminate or suspend your access to the App at any time, without notice or liability, if I believe you have violated these Terms or any applicable law. Upon termination, your right to use the App will immediately cease.</p>
          <br>

          <h2>8. Disclaimers</h2>
          <p>The App is provided "as is" and "as available" without warranties of any kind, either express or implied. I do not warrant that the App will be uninterrupted or error-free.</p>
          <br>

          <h2>9. Limitation of Liability</h2>
          <p>To the fullest extent permitted by law, in no event will I be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the App.</p>
          <br>

          <h2>10. Governing Law</h2>
          <p>These Terms are governed by and construed in accordance with the laws of Europe, without regard to its conflict of law principles.</p>
          <br>

          <h2>11. Changes to Terms</h2>
          <p>I reserve the right to modify these Terms at any time. I will notify you of any changes by posting the new Terms on the App. Your continued use of the App after such changes constitutes your acceptance of the new Terms.</p>
          <br>

          <h2>12. Contact Me</h2>
          <p>If you have any questions about these Terms, please contact me at <a href="mailto:grocer-mate@vladutteodor.ro">grocer-mate@vladutteodor.ro</a>.</p>

        </div>
      </div>
    </div>    <!-- Contact End -->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import TitleArea from "../News/TitleArea.vue";
import HeaderHomeSeven from "@/components/HomeSeven/HeaderHomeSeven.vue";
export default {
  components: {
    HeaderHomeSeven,
    SidebarHomeOne,
    TitleArea,
    FooterHomeOne,
  },
  data() {
    return {
      sidebar: false,
      navs: [
        {
          name: "home",
          path: '/'
        },
        {
          name: "Downloads",
          childrens: [
            {
              name: "Android",
              href: "https://play.google.ro",
            },
            {
              name: "IOS",
              href: "https://play.apple.com",
            },
          ]
        },
        {
          name: "Terms",
          path: "/terms"
        },
        {
          name: "Privacy",
          path: "/privacy"
        },
        {
          name: "Changelog",
          path: "/changelog"
        },
        {
          name: "contact",
          path: "/contact",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>