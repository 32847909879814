import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeSeven from '../views/HomeSeven.vue'
import Contact from '../views/Contact.vue'
import Error from '../views/Error.vue'
import Terms from "@/views/Terms.vue";
import Privacy from "@/views/Privacy.vue";
import TestApp from "../views/TestApp.vue";
import Changelog from "@/views/Changelog.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeOne',
    component: HomeSeven
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  },
  {
    path: '/test-app2',
    name: 'Test',
    component: TestApp
  }

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // stop cache
  scrollBehavior() {
      document.getElementById('app').scrollIntoView();
  }
})

export default router
