<template>
      <!--====== APPIE COUNTER PART START ======-->
    
    <section class="appie-counter-area pt-90 pb-190" id="mycounter">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title">
                        <h3 class="appie-title">{{title}}</h3>
                        <p>{{description}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="appie-single-counter mt-30 item-4 wow animated fadeInUp">
                  <div class="counter-content">
                    <div class="icon">
                      <img src="@/assets/images/new-icons/file-sharing.png" width="64" alt="">
                    </div>
                    <p>Share list</p>
                    <small>Easily share your shopping list with friends and family.</small>
                  </div>
                </div>
              </div>

                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-counter mt-30 wow animated fadeInUp">
                        <div class="counter-content">
                            <div class="icon">
                                <img src="@/assets/images/new-icons/shopping-list.png" width="64" alt="">
                            </div>
                            <p>Unlimited Shopping lists</p>
                            <small>Create and manage unlimited shopping lists effortlessly.</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-counter mt-30 item-2 wow animated fadeInUp">
                        <div class="counter-content">
                            <div class="icon">
                                <img src="@/assets/images/new-icons/balance.png" width="64" alt="">
                            </div>
                            <p>Quantities and units</p>
                            <small>Add quantities and units for precise shopping.</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-counter mt-30 item-3 wow animated fadeInUp">
                        <div class="counter-content">
                            <div class="icon">
                                <img src="@/assets/images/new-icons/camera.png" width="64" alt="">
                            </div>
                            <p>Photos (incoming)</p>
                          <small>Attach photos for clearer item identification.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE COUNTER PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        },
    },
    data() {
      return {
        showCounters: false,
        counterPositions:null,
      };
    },
    mounted() {
        var rec = document.getElementById('mycounter')
        var currentPosition = rec.offsetTop - document.body.scrollTop;
        this.counterPositions=currentPosition
        window.addEventListener('scroll',()=>{
            var currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if(this.counterPositions !== null){
                if(currentScrollPosition + 500>this.counterPositions){
                    this.examplem()  
                }
            }
            
        })
    },
    methods: {
      examplem(){
        this.showCounters = true;
        this.counterPositions=null
      }
    }

}
</script>

<style>

</style>